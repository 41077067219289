.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
  .spinslow {
    animation: spinslow-spin infinite 20s linear;
  }
  .bulge-music {
    animation: bulge-music infinite 1s linear;
  }
  .bulge-slow {
    animation: bulge-slow infinite 15s ease-in-out;
  }
  .bulge-girl {
    animation: bulge-girl infinite 10s ease-in-out;
  }
  .bulge-girl2 {
    animation: bulge-girl2 infinite 2.5s ease-in-out;
  }
}

@keyframes spinslow-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bulge-music {
  0% {
    transform: scale(0.5);
  }
  5% {
    transform: scale(0.7);
  }
  25% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.4);
  }
  55% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(0.5);
  }
  80% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.5);
  }
}

@keyframes bulge-slow {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bulge-girl {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.975);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bulge-girl2 {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}
