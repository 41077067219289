body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.imgContainer {
  min-width: 250px;
  min-height: 250px;
  max-width: 250px;
  max-height: 250px;
  padding: 0;
  display: inline-block;
  position: relative;
  text-align: center;
  overflow: hidden;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  border-radius: 0.75em;
  margin: 0.25em;
}

div.imgContainer img {
  max-width: 100%;
  max-height: 100%;
}

div.imgContainer video {
  max-width: 100%;
  max-height: 100%;
}

div.imgContainer .wideArt {
  max-width: 90%;
  max-height: 90%;
  margin-top: 1em;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 2.5%;
  left: 2.5%;
  right: 2.5%;
  background-color: #ffffffcc;
  border-radius: 0.5em;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.65);
  border-style: solid;
  font-variant-caps: small-caps;
  z-index: 10;
  padding: 0.125em;
}

.top-left {
  position: absolute;
  height: 2em;
  background-color: #ffffffcc;
  border-radius: 0.5em;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.65);
  border-style: solid;
  font-variant-caps: small-caps;
  z-index: 10;
  padding: 0.125em;
  top: 0.5em;
  left: 1em;
}

.top-left2 {
  position: absolute;
  height: 2em;
  right: 1em;
  top: 0.5em;

  background-color: #ffffffcc;
  border-radius: 0.5em;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.65);
  border-style: solid;
  font-variant-caps: small-caps;
  z-index: 10;
  padding: 0.125em;
}

.innerBorder {
  border-width: 1px;
  border-color: black;
  border-style: solid;
}

/* Bottom left text */
.centerBackground {
  position: absolute;
  bottom: 5%;
  top: 5%;
  left: 5%;
  right: 5%;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 10%;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 10%;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.downloadContainer {
  min-width: 250px;
  min-height: 250px;
  max-width: 250px;
  max-height: 250px;
  padding: 0;
  display: inline-block;
  position: relative;
  text-align: center;
  overflow: hidden;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  border-radius: 0.75em;
  margin: 0.5em;
}

.pushToTop {
  margin-top: 0 !important;
}

.pushNearTop {
  margin-top: 0.5em !important;
}

.smallerVideo {
  max-width: 80%;
  max-height: 80%;
}

.growvideo {
  width: 100%;
  height: 100%;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.waveformgif {
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.darkturquoise {
  background-color: darkturquoise;
}

.backgroundFULL {
  width: 100%;
  height: 100%;

  position: absolute;
  bottom: 0px;
  right: 0px;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.pillBoxWatch {
  display: inline-block;
  position: relative;
  text-align: center;
  overflow: hidden;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  border-radius: 0.75em;
  margin: 0.25em;

  font-variant-caps: small-caps;
}

.clear {
  clear: both;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .TopLevelContainer {
    margin-left: 0;
    margin-right: 0;
  }

  .AppMargin {
    margin-left: 0;
    margin-right: 0;
  }

  .TopLevel {
    border-top-style: solid;
    border-bottom-style: solid;
    border-color: black;

    border-width: 1px;
    border-width: 1px;
    margin: 1em;
  }

  .wideOnly {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600.001px) {
  .TopLevelContainer {
    max-width: 1090px;
  }

  .AppMargin {
    margin-left: 1em;
    margin-right: 1em;
  }

  .TopLevel {
    border-style: solid;
    border-color: black;
    border-radius: 1em;
    border-width: 1px;
    margin: 1em;
  }

  .narrowOnly {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .wide2Only {
    display: none;
  }
}

@media only screen and (min-width: 901px) {
  .nonWide2Only {
    display: none;
  }
}

.NavBarAlignInner {
  margin-left: auto;
  margin-right: unset !important;
}

.beatborderoutter {
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: black;
  display: inline-block;
  line-height: 0;
}

.beatborder {
  border-left-style: solid;
  border-width: 1px;
  height: 1em;
  width: 1em;
  display: inline-block;
}

.beatBox {
  animation: blink 2s infinite;
}

.beatBoxActive {
  animation: blinkActive 2s infinite;
  background-color: greenyellow;
}

.beatBox0 {
  animation-delay: 0s;
}

.beatBox1 {
  animation-delay: 0.125s;
}

.beatBox2 {
  animation-delay: 0.25s;
}

.beatBox3 {
  animation-delay: 0.375s;
}

.beatBox4 {
  animation-delay: 0.5s;
}

.beatBox5 {
  animation-delay: 0.625s;
}

.beatBox6 {
  animation-delay: 0.75s;
}

.beatBox7 {
  animation-delay: 0.875s;
}

.beatBox8 {
  animation-delay: 1s;
}

.beatBox9 {
  animation-delay: 1.125s;
}

.beatBox10 {
  animation-delay: 1.25s;
}

.beatBox11 {
  animation-delay: 1.375s;
}

.beatBox12 {
  animation-delay: 1.5s;
}

.beatBox13 {
  animation-delay: 1.625s;
}

.beatBox14 {
  animation-delay: 1.75s;
}

.beatBox15 {
  animation-delay: 1.875s;
}

@keyframes blink {
  0% {
    background-color: lightseagreen;
  }

  6.24% {
    background-color: lightseagreen;
  }

  6.25% {
    background-color: white;
  }

  100% {
    background-color: white;
  }
}

@keyframes blinkActive {
  0% {
    background-color: red;
  }

  6.24% {
    background-color: red;
  }

  6.25% {
    background-color: greenyellow;
  }

  100% {
    background-color: greenyellow;
  }
}

.centerBlock,
div .centerBlock {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.centerText {
  text-align: center;
}
/*
.threeJSShip {}

#threejs {}

.threeJSShip>div {}

#threejs>div>canvas {}
*/
.shipLogo {
  vertical-align: top;
  height: 4em;
  width: 4em;
  border-radius: 1em;
  margin-bottom: 0;
}

.shipLogoSmall {
  vertical-align: top;
  height: 32px;
  width: 32px;
  border-radius: 0.5em;
  margin: 0.25em;
  margin-bottom: 0;
  border: solid;
  border-width: 1px;
  border-color: #ffffffaa;

  background-color: white;
}

.aboutImage {
  border-radius: 1em;
  border-style: solid;
  border-width: 1px;
  background-color: white;

  /*border-color: #fff;
   */
  border-color: lightgray;
}

.tight {
  padding: 1em;
}

.veryTight {
  padding: 0.25em;
  border-radius: 0.75em;
}

p {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

@media screen and (max-width: 479px) {
  .hideIfNarrow {
    display: none !important;
  }
}

@media screen and (min-width: 480px) {
  .showIfWide {
    display: none !important;
  }
}

.leaf {
  background-image: url("/public/borders/decoright.png");
  background-repeat: no-repeat;
  height: 50px;
  width: 175px;
}

.flipX {
  transform: scaleX(-1);
}

.flipY {
  transform: scaleY(-1);
}

.flipXY {
  transform: scale(-1, -1);
}

.top {
  top: 1em;
}

.right {
  right: -25px;
}
.bottom {
  bottom: 2em;
}

.left {
  left: -25px;
}

.myBackgroundAndBorderColorAndTextColor {
  background-color: white;

  color: black;
  border-width: 2px;
  border-color: black;
}

h2,
h3 {
  font-size: 1.17em;
}
h1,
h2,
h3,
h4 {
  font-weight: 500;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.topTitle {
  text-align: center;
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  top: 0px;
  margin: 0;
  padding-top: 0.125em;
  padding-bottom: 0.125em;

  border-color: lightgray;
  border-width: 1px;
  background-color: white;
}
.topTitle > h1 {
  font-family: serif;
}
.topTitle > h2,
.topTitle > h3 {
  font-weight: 100;
}

.innerBorderColor {
  border-color: lightgray;
}
/*
.title {

}
*/
.limitWidth {
  width: 960px;
  max-width: 90vw;
}
.doubleMargin {
  width: 960px;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.SectionText {
  text-align: justify;
}

@media screen and (max-width: 491px) {
  .card {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 535px;
    width: 312px;
  }
  .cardThumb {
    width: "50px";
  }
  .removehorizontalMarginForTarot {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 492px) {
  .card {
    float: right;
    height: 535px;
    width: 312px;
  }
  .cardThumb {
    width: "50px";
  }
}

.topLink {
  color: black;
  margin: 0;
  text-decoration: none;
  border-left-style: solid;
  border-width: 1px;
  padding: 0.125em;
  border-bottom-style: solid;
  z-index: 43;
}
.topLink:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 0.5em;
  padding-right: 0.25em;
}
.topLink:first-child {
  border-bottom-left-radius: 0.5em;
  padding-left: 0.25em;
}
.toplinkActive {
  background-color: black;
  color: white;
  border-bottom-color: black;
}

.firstItemMargin {
  margin-top: 0.5em;
}

.pillBox {
  padding: 0;
  display: inline-block;
  position: relative;
  text-align: center;
  overflow: hidden;
  border-style: solid;
  border-radius: 0.75em;
  margin: 0.25em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 0em;
  font-variant-caps: small-caps;
  border-color: black;
  border-width: 2px;

  background-color: white;
}

@media screen and (min-width: 492px) {
  .pillBoxResponsive {
    padding: 0;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-style: solid;
    border-radius: 0.75em;
    margin: 0.25em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0em;
    font-variant-caps: small-caps;
    border-color: white;
    border-width: 2px;
  }
  .leftLeafRelative,
  .rightLeafRelative {
    background-position-x: -5px;
  }
}

@media screen and (max-width: 491px) {
  .pillBoxResponsive {
    padding: 0;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    margin-top: 0.125em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.125em;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0em;
    font-variant-caps: small-caps;
    border-color: white;
    border-bottom: solid;
  }
  .leftLeafRelative,
  .rightLeafRelative {
    background-position-x: -55px;
  }
}

.footerCopyright {
  background-color: white;
  margin-top: 0.5em;
  padding: 0.5em;
  text-decoration: none;
  border-radius: 0.5em;
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  color: black;
}

.pillBoxResponsiveWide {
  padding: 0;
  display: inline-block;
  position: relative;
  text-align: center;
  overflow: hidden;
  font-variant-caps: small-caps;
  border-color: white;
  border-width: 2px;
  margin-bottom: 0em;
}
@media screen and (min-width: 707px) {
  .pillBoxResponsiveWide {
    border-style: solid;
    border-radius: 0.75em;
    margin: 0.25em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 90vw;
  }
}

@media screen and (max-width: 706px) {
  .pillBoxResponsiveWide {
    margin-top: 0.125em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.125em;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.topLevelMargin {
  margin-top: 0.5em;
}

.tarotIcon {
  width: 58px;
  height: 100px;
  margin: 0.1em;
  border-radius: 0.25em;
  border-width: 1px;
  border-color: #ffffff;
  border-style: solid;
}

.tarotBig {
  border-radius: 0.5em;
  border-color: white;
  border-width: 1px;
  border-style: solid;
}

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
}

.mc-banner {
  display: block;
  border-radius: 1em;
  margin-right: 2.5vw;
  margin-left: 2.5vw;
  max-width: 95vw;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
  max-height: 95vh;
}

.modalContent__image {
  background-color: #000;
}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.selectEnable { 
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}
